/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "./utils";
import { Flex, Icon, View } from "@aws-amplify/ui-react";
export default function NavBar(props) {
  const { displayMode, overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Vector4487342: {},
        Indicator4487343: {},
        Home: {},
        Vector4487345: {},
        Indicator4487353: {},
        Community: {},
        Vector4487347: {},
        Indicator4487354: {},
        Map: {},
        Vector4487349: {},
        Indicator4487355: {},
        Marketplace: {},
        Indicator4487356: {},
        Profile4487351: {},
        "Frame 460": {},
        Profile4487350: {},
        NavBar: {},
      },
      variantValues: { displayMode: "Friends" },
    },
    {
      overrides: {
        Vector4487342: {},
        Indicator4487343: { display: "none" },
        Home: {},
        Vector4487345: {},
        Indicator4487353: { display: "block" },
        Community: {},
        Vector4487347: {},
        Indicator4487354: {},
        Map: {},
        Vector4487349: {},
        Indicator4487355: {},
        Marketplace: {},
        Indicator4487356: {},
        Profile4487351: {},
        "Frame 460": {},
        Profile4487350: {},
        NavBar: {},
      },
      variantValues: { displayMode: "Community" },
    },
    {
      overrides: {
        Vector4487342: {},
        Indicator4487343: { display: "none" },
        Home: {},
        Vector4487345: {},
        Indicator4487353: {},
        Community: {},
        Vector4487347: {},
        Indicator4487354: { display: "block" },
        Map: {},
        Vector4487349: {},
        Indicator4487355: {},
        Marketplace: {},
        Indicator4487356: {},
        Profile4487351: {},
        "Frame 460": {},
        Profile4487350: {},
        NavBar: {},
      },
      variantValues: { displayMode: "Map" },
    },
    {
      overrides: {
        Vector4487342: {},
        Indicator4487343: { display: "none" },
        Home: {},
        Vector4487345: {},
        Indicator4487353: {},
        Community: {},
        Vector4487347: {},
        Indicator4487354: {},
        Map: {},
        Vector4487349: {},
        Indicator4487355: { display: "block" },
        Marketplace: {},
        Indicator4487356: {},
        Profile4487351: {},
        "Frame 460": {},
        Profile4487350: {},
        NavBar: {},
      },
      variantValues: { displayMode: "Marketplace" },
    },
    {
      overrides: {
        Vector4487342: {},
        Indicator4487343: { display: "none" },
        Home: {},
        Vector4487345: {},
        Indicator4487353: {},
        Community: {},
        Vector4487347: {},
        Indicator4487354: {},
        Map: {},
        Vector4487349: {},
        Indicator4487355: {},
        Marketplace: {},
        Indicator4487356: { display: "block" },
        Profile4487351: {},
        "Frame 460": {},
        Profile4487350: {},
        NavBar: {},
      },
      variantValues: { displayMode: "Profile" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  const homeOnClick = useNavigateAction({ type: "url", url: "/" });
  const communityOnClick = useNavigateAction({
    type: "url",
    url: "/community",
  });
  const mapOnClick = useNavigateAction({ type: "url", url: "/map" });
  const marketplaceOnClick = useNavigateAction({
    type: "url",
    url: "/marketplace",
  });
  const profileFourFourEightSevenThreeFiveZeroOnClick = useNavigateAction({
    type: "url",
    url: "/profile",
  });
  return (
    <Flex
      gap="0"
      direction="row"
      width="428px"
      height="55px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      boxShadow="0px 8px 20px rgba(0.9921568632125854, 0.8549019694328308, 0.05098039284348488, 1)"
      padding="0px 5px 0px 5px"
      backgroundColor="rgba(29,28,21,1)"
      display="flex"
      {...getOverrideProps(overrides, "NavBar")}
      {...rest}
    >
      <View
        width="unset"
        height="55px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        displaymode=""
        onClick={() => {
          homeOnClick();
        }}
        {...getOverrideProps(overrides, "Home")}
      >
        <Icon
          width="25px"
          height="25px"
          viewBox={{ minX: 0, minY: 0, width: 24.997873306274414, height: 25 }}
          paths={[
            {
              d: "M23.6024 14.043C24.3403 14.043 24.9913 13.3594 24.9913 12.4756C25.0347 12.0361 24.8611 11.6455 24.5139 11.3037L22.2222 9.0332L22.2222 3.125C22.2222 2.26074 21.6016 1.5625 20.8333 1.5625L19.4444 1.5625C18.6762 1.5625 18.0556 2.26074 18.0556 3.125L18.0556 4.91699L13.4332 0.341797C13.1727 0.0976562 12.8255 0 12.5217 0C12.2179 0 11.8707 0.0488281 11.5668 0.390625L0.434028 11.3037C0.130208 11.6455 0 12.0361 0 12.4756C0 13.3545 0.607639 14.043 1.38889 14.043L2.77778 14.043L2.77778 17.4463C2.77344 17.4902 2.77344 17.5342 2.77344 17.583L2.77344 23.0469C2.77344 24.126 3.55035 25 4.50955 25L5.20399 25C5.25608 25 5.30816 24.9951 5.36024 24.9902C5.42535 24.9951 5.49045 25 5.55556 25L6.94444 25L7.98611 25C8.94531 25 9.72222 24.126 9.72222 23.0469L9.72222 21.875L9.72222 18.75C9.72222 17.8857 10.3429 17.1875 11.1111 17.1875L13.8889 17.1875C14.6571 17.1875 15.2778 17.8857 15.2778 18.75L15.2778 21.875L15.2778 23.0469C15.2778 24.126 16.0547 25 17.0139 25L18.0556 25L19.4661 25C19.5269 25 19.5877 25 19.6484 24.9951C19.6962 25 19.7439 25 19.7917 25L20.4861 25C21.4453 25 22.2222 24.126 22.2222 23.0469L22.2222 22.2559C22.2352 22.1289 22.2439 21.9971 22.2439 21.8604L22.2135 14.0381L23.6024 14.0381L23.6024 14.043Z",
              fill: "rgba(253,218,13,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="calc(50% - 12.5px - -1px)"
          left="calc(50% - 12.5px - 0.3px)"
          {...getOverrideProps(overrides, "Vector4487342")}
        ></Icon>
        <View
          width="560px"
          height="3px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(253,218,13,1)"
          {...getOverrideProps(overrides, "Indicator4487343")}
        ></View>
      </View>
      <View
        width="unset"
        height="55px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        onClick={() => {
          communityOnClick();
        }}
        {...getOverrideProps(overrides, "Community")}
      >
        <Icon
          width="25px"
          height="25px"
          viewBox={{ minX: 0, minY: 0, width: 25, height: 25 }}
          paths={[
            {
              d: "M17.1875 12.5C17.1875 13.584 17.1289 14.6289 17.0264 15.625L7.97363 15.625C7.86621 14.6289 7.8125 13.584 7.8125 12.5C7.8125 11.416 7.87109 10.3711 7.97363 9.375L17.0264 9.375C17.1338 10.3711 17.1875 11.416 17.1875 12.5ZM18.5938 9.375L24.6045 9.375C24.8633 10.376 25 11.4209 25 12.5C25 13.5791 24.8633 14.624 24.6045 15.625L18.5938 15.625C18.6963 14.6191 18.75 13.5742 18.75 12.5C18.75 11.4258 18.6963 10.3809 18.5938 9.375ZM24.0918 7.8125L18.3936 7.8125C17.9053 4.69238 16.9385 2.08008 15.6934 0.410156C19.5166 1.4209 22.627 4.19434 24.0869 7.8125L24.0918 7.8125ZM16.8115 7.8125L8.18848 7.8125C8.48633 6.03516 8.94531 4.46289 9.50684 3.18848C10.0195 2.03613 10.5908 1.20117 11.1426 0.673828C11.6895 0.15625 12.1436 0 12.5 0C12.8564 0 13.3105 0.15625 13.8574 0.673828C14.4092 1.20117 14.9805 2.03613 15.4932 3.18848C16.0596 4.45801 16.5137 6.03027 16.8115 7.8125ZM6.60645 7.8125L0.908203 7.8125C2.37305 4.19434 5.47852 1.4209 9.30664 0.410156C8.06152 2.08008 7.09473 4.69238 6.60645 7.8125ZM0.395508 9.375L6.40625 9.375C6.30371 10.3809 6.25 11.4258 6.25 12.5C6.25 13.5742 6.30371 14.6191 6.40625 15.625L0.395508 15.625C0.136719 14.624 0 13.5791 0 12.5C0 11.4209 0.136719 10.376 0.395508 9.375ZM9.50684 21.8066C8.94043 20.5371 8.48633 18.9648 8.18848 17.1875L16.8115 17.1875C16.5137 18.9648 16.0547 20.5371 15.4932 21.8066C14.9805 22.959 14.4092 23.7939 13.8574 24.3213C13.3105 24.8438 12.8564 25 12.5 25C12.1436 25 11.6895 24.8438 11.1426 24.3262C10.5908 23.7988 10.0195 22.9639 9.50684 21.8115L9.50684 21.8066ZM6.60645 17.1875C7.09473 20.3076 8.06152 22.9199 9.30664 24.5898C5.47852 23.5791 2.37305 20.8057 0.908203 17.1875L6.60645 17.1875ZM24.0918 17.1875C22.627 20.8057 19.5215 23.5791 15.6982 24.5898C16.9434 22.9199 17.9053 20.3076 18.3984 17.1875L24.0918 17.1875Z",
              fill: "rgba(253,218,13,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="calc(50% - 12.5px - -1px)"
          left="calc(50% - 12.5px - 0.3px)"
          {...getOverrideProps(overrides, "Vector4487345")}
        ></Icon>
        <View
          width="560px"
          height="3px"
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0.4px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(253,218,13,1)"
          {...getOverrideProps(overrides, "Indicator4487353")}
        ></View>
      </View>
      <View
        width="unset"
        height="55px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        onClick={() => {
          mapOnClick();
        }}
        {...getOverrideProps(overrides, "Map")}
      >
        <Icon
          width="25px"
          height="24.63px"
          viewBox={{ minX: 0, minY: 0, width: 25, height: 24.630775451660156 }}
          paths={[
            {
              d: "M17.7083 5.85938C17.7083 8.52539 14.5356 13.2764 13.1424 15.2344C12.8082 15.7031 12.1875 15.7031 11.8576 15.2344C10.4644 13.2764 7.29167 8.52539 7.29167 5.85938C7.29167 2.62207 9.6224 0 12.5 0C15.3776 0 17.7083 2.62207 17.7083 5.85938ZM18.0556 9.78516C18.2075 9.44824 18.3464 9.11133 18.4722 8.7793C18.4939 8.7207 18.5156 8.65723 18.5373 8.59863L23.572 6.33301C24.2578 6.02539 25 6.5918 25 7.42188L25 20.6445C25 21.123 24.7396 21.5527 24.3446 21.7334L18.0556 24.5605L18.0556 9.78516ZM5.97222 6.75293C6.07639 7.44141 6.28472 8.13477 6.52778 8.7793C6.65365 9.11133 6.79253 9.44824 6.94444 9.78516L6.94444 22.0605L1.42795 24.5459C0.742188 24.8535 0 24.2871 0 23.457L0 10.2344C0 9.75586 0.260417 9.32617 0.655382 9.14551L5.97656 6.75293L5.97222 6.75293ZM14.2274 16.2109C14.8307 15.3613 15.7769 13.9795 16.6667 12.4512L16.6667 24.624L8.33333 21.9434L8.33333 12.4512C9.22309 13.9795 10.1693 15.3613 10.7726 16.2109C11.6623 17.4609 13.3377 17.4609 14.2274 16.2109ZM12.5 7.42188C12.9604 7.42188 13.402 7.2161 13.7276 6.84982C14.0532 6.48354 14.2361 5.98675 14.2361 5.46875C14.2361 4.95075 14.0532 4.45396 13.7276 4.08768C13.402 3.7214 12.9604 3.51562 12.5 3.51562C12.0396 3.51562 11.598 3.7214 11.2724 4.08768C10.9468 4.45396 10.7639 4.95075 10.7639 5.46875C10.7639 5.98675 10.9468 6.48354 11.2724 6.84982C11.598 7.2161 12.0396 7.42188 12.5 7.42188Z",
              fill: "rgba(253,218,13,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="calc(50% - 12.32px - -0.82px)"
          left="calc(50% - 12.5px - 0.3px)"
          {...getOverrideProps(overrides, "Vector4487347")}
        ></Icon>
        <View
          width="560px"
          height="3px"
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="-0.2px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(253,218,13,1)"
          {...getOverrideProps(overrides, "Indicator4487354")}
        ></View>
      </View>
      <View
        width="unset"
        height="55px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        onClick={() => {
          marketplaceOnClick();
        }}
        {...getOverrideProps(overrides, "Marketplace")}
      >
        <Icon
          width="24.41px"
          height="21.36px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 24.411272048950195,
            height: 21.359880447387695,
          }}
          paths={[
            {
              d: "M0 2.33888L0 9.63869C0 10.4688 0.373884 11.2647 1.04353 11.8506L10.865 20.4444C12.26 21.6651 14.5201 21.6651 15.9152 20.4444L23.365 13.9258C24.76 12.7051 24.76 10.7276 23.365 9.50685L13.5435 0.913103C12.8739 0.327165 11.9643 1.69643e-05 11.0156 1.69643e-05L2.67857 1.69643e-05C1.19978 -0.00486585 0 1.04494 0 2.33888ZM6.25 3.90138C6.7236 3.90138 7.1778 4.066 7.51269 4.35903C7.84758 4.65206 8.03571 5.04948 8.03571 5.46388C8.03571 5.87829 7.84758 6.27571 7.51269 6.56874C7.1778 6.86176 6.7236 7.02638 6.25 7.02638C5.7764 7.02638 5.3222 6.86176 4.98731 6.56874C4.65242 6.27571 4.46429 5.87829 4.46429 5.46388C4.46429 5.04948 4.65242 4.65206 4.98731 4.35903C5.3222 4.066 5.7764 3.90138 6.25 3.90138Z",
              fill: "rgba(253,218,13,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="calc(50% - 10.68px - -0.75px)"
          left="calc(50% - 12.21px - 0.59px)"
          {...getOverrideProps(overrides, "Vector4487349")}
        ></Icon>
        <View
          width="560px"
          height="3px"
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0.2px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(253,218,13,1)"
          {...getOverrideProps(overrides, "Indicator4487355")}
        ></View>
      </View>
      <View
        width="unset"
        height="55px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        onClick={() => {
          profileFourFourEightSevenThreeFiveZeroOnClick();
        }}
        {...getOverrideProps(overrides, "Profile4487350")}
      >
        <View
          width="560px"
          height="3px"
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="-255.4px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(253,218,13,1)"
          {...getOverrideProps(overrides, "Indicator4487356")}
        ></View>
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          position="absolute"
          top="calc(50% - 27.5px - 0px)"
          left="calc(50% - 41.5px - 0.7px)"
          padding="15px 29px 15px 29px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 460")}
        >
          <View
            width="25px"
            height="25px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(217,217,217,1)"
            {...getOverrideProps(overrides, "Profile4487351")}
          ></View>
        </Flex>
      </View>
    </Flex>
  );
}
