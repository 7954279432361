import './App.css';

import { fetchUserAttributes } from '@aws-amplify/auth';

import { TopBar, NavBar, GridRow } from './ui-components/index.js';
import { Friends, Community, Map, Marketplace, Profile, ContentWindow } from './customcomps/index.js';
import { Routes, Route } from 'react-router-dom';

//Enables <head> info
import { Helmet, HelmetProvider } from 'react-helmet-async';

//auth dependencies
import { Amplify, Storage } from 'aws-amplify';

import { withAuthenticator, AmplifyS3Image, View, Image } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Auth } from 'aws-amplify';


/*
import { downloadData } from 'aws-amplify/storage';

const { body, eTag } = await downloadData({

  key,
  data: file,

  options: {

    accessLevel: 'guest', // access level of the file being downloaded

    targetIdentityId: 'xxxxxxx', // the identity id of another user, required when setting accessLevel to 'protected'

    onProgress: (event) => {
      console.log(event.transferredBytes);
    } // optional progress callback

  }

}).result;

*/


function App({ signOut, user }) {
  const helmetContext = {};

  

  
  return (
    <HelmetProvider context={helmetContext}>

      <Helmet>
        <title>Friends - Octane</title>

        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
		    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
		    <link href="https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@300&display=swap" rel="stylesheet"></link>
		    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Kanit"></link>
      </Helmet>

        <Routes>

          <Route path='/' element={<Friends />} />
          <Route path='/community' element={<Community />} />
          <Route path='/map' element={<Map />} />
          <Route path='/marketplace' element={<Marketplace />} />
          <Route path='/profile' element={<Profile />} />
      
        </Routes>

      
      <TopBar
          className="topbar"
          width="100vw"

      />

      <View
        height='55px'
        width='100px'
      >
      </View>

      <GridRow>
        
      </GridRow>

      
    </HelmetProvider>
  );
}

export default withAuthenticator(App);