/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Icon, Image, Text, View } from "@aws-amplify/ui-react";
export default function TopBar(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="428px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "TopBar")}
      {...rest}
    >
      <Flex
        gap="9px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 8px 20px rgba(0.9921568632125854, 0.8549019694328308, 0.05098039284348488, 0.25)"
        padding="4px 0px 4px 0px"
        backgroundColor="rgba(29,28,21,1)"
        {...getOverrideProps(overrides, "TopBarFlex")}
      >
        <Image
          width="49px"
          height="47px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://s3.amazonaws.com/octane.media/Octane_Logo_Official_1000x1000.png"
          {...getOverrideProps(overrides, "Octane_Logo_Official_1000x1000 1")}
        ></Image>
        <View
          width="unset"
          height="35px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          borderRadius="24px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(209,209,209,1)"
          {...getOverrideProps(overrides, "Searchbar")}
        >
          <View
            width="18px"
            height="18px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            position="absolute"
            top="8.5px"
            left="11px"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "magnifying-glass-solid 1")}
          >
            <Icon
              width="18px"
              height="18px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 17.999120712280273,
                height: 18.00263786315918,
              }}
              paths={[
                {
                  d: "M14.625 7.3125C14.625 8.92617 14.1012 10.4168 13.2188 11.6262L17.6695 16.0805C18.109 16.5199 18.109 17.2336 17.6695 17.673C17.2301 18.1125 16.5164 18.1125 16.077 17.673L11.6262 13.2188C10.4168 14.1047 8.92617 14.625 7.3125 14.625C3.27305 14.625 0 11.352 0 7.3125C0 3.27305 3.27305 0 7.3125 0C11.352 0 14.625 3.27305 14.625 7.3125ZM7.3125 12.375C7.97732 12.375 8.63562 12.2441 9.24984 11.9896C9.86405 11.7352 10.4221 11.3623 10.8922 10.8922C11.3623 10.4221 11.7352 9.86405 11.9896 9.24984C12.2441 8.63562 12.375 7.97732 12.375 7.3125C12.375 6.64768 12.2441 5.98938 11.9896 5.37516C11.7352 4.76095 11.3623 4.20287 10.8922 3.73277C10.4221 3.26268 9.86405 2.88977 9.24984 2.63536C8.63562 2.38095 7.97732 2.25 7.3125 2.25C6.64768 2.25 5.98938 2.38095 5.37516 2.63536C4.76095 2.88977 4.20287 3.26268 3.73277 3.73277C3.26268 4.20287 2.88977 4.76095 2.63536 5.37516C2.38095 5.98938 2.25 6.64768 2.25 7.3125C2.25 7.97732 2.38095 8.63562 2.63536 9.24984C2.88977 9.86405 3.26268 10.4221 3.73277 10.8922C4.20287 11.3623 4.76095 11.7352 5.37516 11.9896C5.98938 12.2441 6.64768 12.375 7.3125 12.375Z",
                  fill: "rgba(0,0,0,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0%"
              bottom="-0.01%"
              left="0%"
              right="0%"
              {...getOverrideProps(overrides, "Vector4408284")}
            ></Icon>
          </View>
          <Text
            fontFamily="Kanit"
            fontSize="20px"
            fontWeight="500"
            color="rgba(0,0,0,1)"
            lineHeight="29.899999618530273px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="236px"
            height="35px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0px"
            left="33px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Search"
            {...getOverrideProps(overrides, "Search")}
          ></Text>
        </View>
        <Flex
          gap="10px"
          direction="row"
          width="110px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Icons")}
        >
          <View
            width="25px"
            height="25px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "plus-solid 1")}
          >
            <Icon
              width="23.21px"
              height="20.31px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 23.214284896850586,
                height: 20.3125,
              }}
              paths={[
                {
                  d: "M13.3929 1.5625C13.3929 0.698242 12.5949 0 11.6071 0C10.6194 0 9.82143 0.698242 9.82143 1.5625L9.82143 8.59375L1.78571 8.59375C0.797991 8.59375 0 9.29199 0 10.1562C0 11.0205 0.797991 11.7188 1.78571 11.7188L9.82143 11.7188L9.82143 18.75C9.82143 19.6143 10.6194 20.3125 11.6071 20.3125C12.5949 20.3125 13.3929 19.6143 13.3929 18.75L13.3929 11.7188L21.4286 11.7188C22.4163 11.7188 23.2143 11.0205 23.2143 10.1562C23.2143 9.29199 22.4163 8.59375 21.4286 8.59375L13.3929 8.59375L13.3929 1.5625Z",
                  fill: "rgba(253,218,13,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="9.38%"
              bottom="9.38%"
              left="3.57%"
              right="3.57%"
              {...getOverrideProps(overrides, "Vector4408288")}
            ></Icon>
          </View>
          <View
            width="25px"
            height="25px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "message-solid 1")}
          >
            <Icon
              width="25px"
              height="25px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 25,
                height: 24.99892234802246,
              }}
              paths={[
                {
                  d: "M3.125 0C1.40137 0 0 1.40137 0 3.125L0 17.1875C0 18.9111 1.40137 20.3125 3.125 20.3125L7.8125 20.3125L7.8125 24.2188C7.8125 24.5166 7.97852 24.7852 8.24219 24.917C8.50586 25.0488 8.82324 25.0195 9.0625 24.8438L15.1025 20.3125L21.875 20.3125C23.5986 20.3125 25 18.9111 25 17.1875L25 3.125C25 1.40137 23.5986 0 21.875 0L3.125 0Z",
                  fill: "rgba(253,218,13,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0%"
              bottom="0%"
              left="0%"
              right="0%"
              {...getOverrideProps(overrides, "Vector4408290")}
            ></Icon>
          </View>
          <View
            width="25px"
            height="25px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "bell-solid 1")}
          >
            <Icon
              width="25px"
              height="25px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 25.000328063964844,
                height: 25,
              }}
              paths={[
                {
                  d: "M12.4989 0C11.5112 0 10.7132 0.698242 10.7132 1.5625L10.7132 2.5C6.63956 3.22266 3.57036 6.37695 3.57036 10.1562L3.57036 11.0742C3.57036 13.3691 2.60496 15.5859 0.863887 17.3047L0.450941 17.71C-0.0178092 18.1689 -0.129416 18.8281 0.155182 19.3896C0.43978 19.9512 1.08152 20.3125 1.78465 20.3125L23.2132 20.3125C23.9163 20.3125 24.5525 19.9512 24.8427 19.3896C25.1329 18.8281 25.0157 18.1689 24.5469 17.71L24.134 17.3047C22.3929 15.5859 21.4275 13.374 21.4275 11.0742L21.4275 10.1562C21.4275 6.37695 18.3583 3.22266 14.2846 2.5L14.2846 1.5625C14.2846 0.698242 13.4867 0 12.4989 0ZM15.0268 24.0869C15.6965 23.501 16.0704 22.7051 16.0704 21.875L12.4989 21.875L8.9275 21.875C8.9275 22.7051 9.30139 23.501 9.97103 24.0869C10.6407 24.6729 11.5503 25 12.4989 25C13.4476 25 14.3572 24.6729 15.0268 24.0869Z",
                  fill: "rgba(253,218,13,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0%"
              bottom="0%"
              left="0%"
              right="0%"
              {...getOverrideProps(overrides, "Vector4408292")}
            ></Icon>
          </View>
        </Flex>
      </Flex>
    </Flex>
  );
}
