import * as React from "react";
import { Text, View } from "@aws-amplify/ui-react";
import { NavBar } from "../ui-components/index.js";
export default function Maps(props) {
  return (
    <>
        <NavBar
          className="navbar"
          width="100vw"
          displayMode="Map"
        />
        <View
            width="100vw"
            height='55px'
        >

        </View>
        <Text
            color='white'
        >Map</Text>
    </>
  );
}
