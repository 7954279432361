/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function ProfileInfo(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="row"
      width="428px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="5px 12px 5px 12px"
      {...getOverrideProps(overrides, "ProfileInfo")}
      {...rest}
    >
      <View
        width="141px"
        height="140px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(253,218,13,1)"
        {...getOverrideProps(overrides, "ProfilePicFrame")}
      >
        <Image
          width="137px"
          height="136px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="2px"
          left="2px"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(overrides, "20231004_172401 1")}
        ></Image>
      </View>
      <View
        width="unset"
        height="140px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(44,44,44,1)"
        {...getOverrideProps(overrides, "Info")}
      >
        <Text
          fontFamily="Kanit"
          fontSize="20px"
          fontWeight="500"
          color="rgba(255,255,255,1)"
          lineHeight="29.899999618530273px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="142px"
          height="28px"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="5px"
          left="13px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Andrew"
          {...getOverrideProps(overrides, "Andrew")}
        ></Text>
        <View
          width="36px"
          height="36px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          position="absolute"
          top="7px"
          left="198px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(253,218,13,1)"
          {...getOverrideProps(overrides, "Frame 462")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="32px"
            height="32px"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            position="absolute"
            top="2px"
            right="2px"
            padding="0px 3px 0px 3px"
            backgroundColor="rgba(29,28,21,1)"
            {...getOverrideProps(overrides, "Frame 463")}
          >
            <Text
              fontFamily="Kanit"
              fontSize="20px"
              fontWeight="500"
              color="rgba(255,255,255,1)"
              lineHeight="29.899999618530273px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="17"
              {...getOverrideProps(overrides, "17")}
            ></Text>
          </Flex>
        </View>
        <Text
          fontFamily="Kanit"
          fontSize="15px"
          fontWeight="500"
          color="rgba(141,141,141,1)"
          lineHeight="22.424999237060547px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="33px"
          left="15px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="13 Friends"
          {...getOverrideProps(overrides, "13 Friends")}
        ></Text>
      </View>
    </Flex>
  );
}
