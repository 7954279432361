import * as React from "react";
import { Text, View } from "@aws-amplify/ui-react";
import { NavBar, CarPicker } from "../ui-components/index.js";

export default function Marketplace(props) {
  return (
    <>
        <NavBar
          className="navbar"
          width="100vw"
          displayMode="Marketplace"
        />
        <View
            width="100vw"
            height='55px'
        >

        </View>
        <Text
            color='white'
        >Marketplace</Text>
        <CarPicker 
            width='100vw'
        />
    </>
  );
}
