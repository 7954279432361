/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Icon, View } from "@aws-amplify/ui-react";
export default function ProfileSelector(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="205px"
      direction="row"
      width="428px"
      height="unset"
      justifyContent="space-between"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="12px 0px 12px 0px"
      backgroundColor="rgba(29,28,21,1)"
      {...getOverrideProps(overrides, "ProfileSelector")}
      {...rest}
    >
      <View
        width="30px"
        height="30px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 464")}
      ></View>
      <View
        width="30px"
        height="28px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "table-cells-solid 1")}
      >
        <Icon
          width="30px"
          height="28px"
          viewBox={{ minX: 0, minY: 0, width: 30, height: 28 }}
          paths={[
            {
              d: "M3.75 0C1.68164 0 0 1.79375 0 4L0 24C0 26.2062 1.68164 28 3.75 28L26.25 28C28.3184 28 30 26.2062 30 24L30 4C30 1.79375 28.3184 0 26.25 0L3.75 0ZM8.90625 4L8.90625 8L3.75 8L3.75 4L8.90625 4ZM12.1875 4L17.3438 4L17.3438 8L12.1875 8L12.1875 4ZM26.25 4L26.25 8L21.0938 8L21.0938 4L26.25 4ZM3.75 12L8.90625 12L8.90625 16L3.75 16L3.75 12ZM17.3438 12L17.3438 16L12.1875 16L12.1875 12L17.3438 12ZM21.0938 12L26.25 12L26.25 16L21.0938 16L21.0938 12ZM8.90625 20L8.90625 24L3.75 24L3.75 20L8.90625 20ZM12.1875 20L17.3438 20L17.3438 24L12.1875 24L12.1875 20ZM26.25 20L26.25 24L21.0938 24L21.0938 20L26.25 20Z",
              fill: "rgba(253,218,13,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          {...getOverrideProps(overrides, "Vector4523366")}
        ></Icon>
      </View>
      <View
        width="4px"
        height="13px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        borderRadius="2px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(253,218,13,1)"
        {...getOverrideProps(overrides, "Spacer")}
      ></View>
      <View
        width="34px"
        height="26px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "warehouse-solid 1")}
      >
        <Icon
          width="34px"
          height="26px"
          viewBox={{ minX: 0, minY: 0, width: 34, height: 26 }}
          paths={[
            {
              d: "M0 24.7753L0 8.61479C0 7.27786 0.844688 6.0787 2.13563 5.58373L16.3678 0.11864C16.7716 -0.0395466 17.2231 -0.0395466 17.6322 0.11864L31.8644 5.58373C33.1553 6.0787 34 7.28296 34 8.61479L34 24.7753C34 25.454 33.4316 26 32.725 26L30.175 26C29.4684 26 28.9 25.454 28.9 24.7753L28.9 11.304C28.9 10.4008 28.1403 9.67107 27.2 9.67107L6.8 9.67107C5.85969 9.67107 5.1 10.4008 5.1 11.304L5.1 24.7753C5.1 25.454 4.53156 26 3.825 26L1.275 26C0.568438 26 0 25.454 0 24.7753ZM25.925 26L8.075 26C7.36844 26 6.8 25.454 6.8 24.7753L6.8 21.9178L27.2 21.9178L27.2 24.7753C27.2 25.454 26.6316 26 25.925 26ZM6.8 20.2849L6.8 17.0191L27.2 17.0191L27.2 20.2849L6.8 20.2849ZM6.8 15.3862L6.8 11.304L27.2 11.304L27.2 15.3862L6.8 15.3862Z",
              fill: "rgba(253,218,13,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          {...getOverrideProps(overrides, "Vector4523364")}
        ></Icon>
      </View>
      <View
        width="30px"
        height="30px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 465")}
      ></View>
    </Flex>
  );
}
