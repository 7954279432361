/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Image } from "@aws-amplify/ui-react";
export default function GridRow(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="1px"
      direction="row"
      width="428px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="1px 0px 1px 0px"
      {...getOverrideProps(overrides, "GridRow")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "GridSquare4542477")}
      >
        <Image
          width="142px"
          height="142px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(overrides, "20231001_000459 1")}
        ></Image>
        <Flex
          width="unset"
          height="unset"
          {...getOverrideProps(overrides, "Aspect ratio4542481")}
        ></Flex>
      </Flex>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "GridSquare4542487")}
      >
        <Image
          width="142px"
          height="142px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(overrides, "DSC_0704 1")}
        ></Image>
        <Flex
          width="unset"
          height="unset"
          {...getOverrideProps(overrides, "Aspect ratio4542491")}
        ></Flex>
      </Flex>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "GridSquare4542507")}
      >
        <Flex
          width="unset"
          height="unset"
          {...getOverrideProps(overrides, "Aspect ratio4542511")}
        ></Flex>
        <Image
          width="142px"
          height="142px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(
            overrides,
            "1970_datsun_240z_15690145075e739b0b106db225819D60A1DA-6225-4653-A34A-C42049EDB7AD 1"
          )}
        ></Image>
      </Flex>
    </Flex>
  );
}
